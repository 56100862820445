<template>
  <div
    v-if="!isFaqEmpty"
    class="px-24"
    :class="{ 'bg-surface-default-low': whiteBackground }"
  >
    <div class="py-56 md:mx-auto md:max-w-[720px]">
      <TitleCtaContainer
        :cta="ctaLink"
        :secondary-layout="true"
        :subtitle
        :title
        :tracking="trackingData"
      >
        <template v-if="showQuestions">
          <RevAccordionList
            class="-mx-20 mt-8 md:mx-0"
            :has-external-borders="false"
          >
            <FaqContentItem
              v-for="(question, index) of questions"
              :key="question.id"
              :position="index + 1"
              :tracking
              v-bind="question"
            />
          </RevAccordionList>
        </template>
      </TitleCtaContainer>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'
import { computed, reactive } from 'vue'

import {
  type FaqApiProps,
  STRUCTURED_DATA_TYPE,
} from '@backmarket/http-api/src/api-specs-content/models/faq'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevAccordionList } from '@ds/components/AccordionList'

import type { ContentBlockProps } from '../../models/content-block'
import TitleCtaContainer from '../../shared-components/TitleCtaContainer/TitleCtaContainer.vue'

import FaqContentItem from './FaqContentItem.vue'
import { buildFaqHead } from './helpers/build-head'

export type FaqProps = FaqApiProps & ContentBlockProps

const props = withDefaults(defineProps<FaqProps>(), {
  tracking: () => reactive({}),
  questions: () => reactive([]),
  whiteBackground: true,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

useHead(
  buildFaqHead({ questions: props.questions, rendering: props.rendering }),
)

const trackingData = computed(() => ({
  ...props.tracking,
  title: props.title,
  name: props.ctaLink?.label || '',
}))

const showQuestions = computed(
  () =>
    !isEmpty(props.questions) &&
    props.rendering !== STRUCTURED_DATA_TYPE.JSON_ONLY,
)

const isFaqEmpty = computed(
  () => isEmpty(props.questions) && isEmpty(props.ctaLink),
)
</script>
